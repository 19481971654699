export const formSections: { [substanceType: string]: Array<string> } = {
    chemical: [
        'substance-form-definition',
        'substance-form-names',
        'substance-form-structure',
        'substance-form-moieties',
        'substance-form-codes-card',
        'substance-form-relationships',
        'substance-form-notes',
        'substance-form-properties',
        'substance-form-references',
        'substance-form-change-reason'
    ],
    chemicalSimplified: [
        'substance-form-simplified-names',
        'substance-form-structure',
        'substance-form-simplified-codes-card',
        'substance-form-simplified-references',
    ],
  protein: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-protein-details',
    'substance-form-subunits',
    'substance-form-other-links',
    'substance-form-disulfide-links',
    'substance-form-glycosylation',
    'substance-form-agent-modifications-card',
    'substance-form-structural-modifications',
    'substance-form-physical-modifications',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-notes',
    'substance-form-properties',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  nucleicAcid: [
    'substance-form-definition',
    'substance-form-names',
    'nucleic-acid-details-form',
    'substance-form-subunits',
    'substance-form-links',
    'substance-form-sugars',
    'substance-form-physical-modifications',
    'substance-form-agent-modifications-card',
    'substance-form-structural-modifications',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-properties',
    'substance-form-notes',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  mixture: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-mixture-details',
    'substance-form-mixture-components',
    'substance-form-agent-modifications-card',
    'substance-form-structural-modifications',
    'substance-form-physical-modifications',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-notes',
    'substance-form-properties',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  structurallyDiverse: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-structurally-diverse-source',
    'substance-form-structurally-diverse-organism',
    'substance-form-agent-modifications-card',
    'substance-form-structural-modifications',
    'substance-form-physical-modifications',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-notes',
    'substance-form-properties',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  concept: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-notes',
    'substance-form-properties',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  specifiedSubstanceG1: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-constituents',
    'substance-form-agent-modifications-card',
    'substance-form-structural-modifications',
    'substance-form-physical-modifications',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-notes',
    'substance-form-properties',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  polymer: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-polymer-classification',
    'substance-form-monomers',
    'substance-form-structure',
    'substance-form-structural-units',
    'substance-form-agent-modifications-card',
    'substance-form-structural-modifications',
    'substance-form-physical-modifications',
    'substance-form-codes-card',
    'substance-form-relationships',
    'substance-form-notes',
    'substance-form-properties',
    'substance-form-references',
    'substance-form-change-reason'
  ],
  specifiedSubstanceG2: [
    'substance-form-ssg2-overview',
    'substance-form-names',
    'substance-form-constituents',
    'substance-form-ssg2-manufacturing',
    'substance-form-references'
  ],
  specifiedSubstanceG3: [
    'substance-form-definition',
    'substance-form-names',
    'substance-form-ssg-parent-substance',
    'substance-form-ssg-definition',
    'substance-form-ssg-grade',
    'substance-form-codes-card',
    'substance-form-notes',
    'substance-form-references'
  ],
  specifiedSubstanceG4m: [
    'substance-form-references',
    'substance-form-ssg-parent-substance',
    'substance-form-ssg4m-process',
  ]
};

